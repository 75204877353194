.hero {
  height: 500px;
  width: 100%;
  left: 100;
  right: 0;
  background-size: cover;
  background-position: center;
  position: relative;
  overflow-x: hidden;
  background-repeat: fixed;
}

.highway {
  height: 150px;
  width: 150%;
  display: block;
  position: absolute;
  bottom: 50;
  left: 100;
  right: 0;
  z-index: 1;
  background-repeat: repeat-x;
  animation: highway 10s linear infinite;

}


